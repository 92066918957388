//指定個所までスクロール
function to_scroll() {
  $('.to_top').click(function() {
    var scroll = $('header').offset().top
    $("html, body").animate({scrollTop: scroll});
  });
}

//フェードアニメーション
var fadeShow = document.querySelectorAll('.fade_data');
var scrollFunc = function() {
  for(var i = 0; i < fadeShow.length; i++) {
    var triggerMargin = 150;
    var elm = fadeShow[i];
    var showPos = 0;
    if(elm.dataset.sa_trigger) {
      showPos = document.querySelector(elm.dataset.sa_trigger).getBoundingClientRect().top + triggerMargin;
    } else {
      showPos = elm.getBoundingClientRect().top + triggerMargin;
    }
    if (window.innerHeight > showPos) {
      elm.classList.add('show');
    } else {
      // elm.classList.remove('show');
    }
    if (showPos <= -300) {
      // elm.classList.remove('show');
    }
  }
}
window.addEventListener('load', scrollFunc);
window.addEventListener('scroll', scrollFunc);

/////////////////////////////////
//スライドテキストアニメーション
/////////////////////////////////
var verticalFade = document.querySelectorAll('.slide_txt_box .txt');
var verticalFadeBg = document.querySelectorAll('.slide_txt_scroll');
var scrollFunc02 = function() {
  for(var i = 0; i < verticalFade.length; i++) {
    var triggerMargin = 150;
    var elm = verticalFade[i];
    var showPos = 0;
    if(elm.dataset.sa_trigger) {
      showPos = document.querySelector(elm.dataset.sa_trigger).getBoundingClientRect().top + triggerMargin;
    } else {
      showPos = elm.getBoundingClientRect().top + triggerMargin;
    }

    // if (window.innerHeight > showPos) {
    //   // elm.classList.add('show');
    //   gsap.to( elm, 2.4, {
    //     // y: "100%",
    //     // scaleY: 1,
    //     // duration: .7,
    //     opacity: 1,
    //     ease: "Power4.easeIn",
    //   });

    // } else {
    //   gsap.to( elm, .3, {
    //     // y: "100%",
    //     // scaleY: 0,
    //     // duration: .7,
    //     opacity: 0,
    //   });
    // }
    // if (showPos <= -300) {
    //   gsap.to( elm, .3, {
    //     // y: "100%",
    //     // scaleY: 0,
    //     // duration: .7,
    //     opacity: 0,
    //   });
    // }
  }
}
// function setTimeScroll() {
//   setTimeout(scrollFunc02, 0);
// }
// window.addEventListener('load', scrollFunc02);
// window.addEventListener('scroll', scrollFunc02);

var controller1 = new ScrollMagic.Controller();

var tween1 = new TimelineMax();
    tween1.staggerFrom($('.slide_txt_scroll_01'), .7, { scaleY: 0, ease: "Power4.easeIn", delay: 1}, .3)
            .to($('.txt01'), 0, {opacity: 1})
            .staggerTo('.slide_txt_scroll_01', 0, {scaleY: 1}, 0)
            .staggerTo($('.slide_txt_scroll_01'), .7, {top: "101%", ease: "Power4.easeOut", delay : .1}, .3)

var scene1 = new ScrollMagic.Scene({
  triggerElement: ".slide_txt_scroll_01",
  reverse: false,
})
.setTween(tween1)
.addTo(controller1);

var tween2 = new TimelineMax();
    tween1.staggerFrom($('.slide_txt_scroll_02'), .7, { scaleY: 0, ease: "Power4.easeIn", delay: 1}, .3)
            .to($('.txt02'), 0, {opacity: 1})
            .staggerTo('.slide_txt_scroll_02', 0, {scaleY: 1}, 0)
            .staggerTo($('.slide_txt_scroll_02'), .7, {top: "101%", ease: "Power4.easeOut", delay : .1}, .3)

var scene2 = new ScrollMagic.Scene({
  triggerElement: ".slide_txt_scroll_02",
  reverse: false,
})
.setTween(tween2)
.addTo(controller1);

var tween3 = new TimelineMax();
    tween1.staggerFrom($('.slide_txt_scroll_03'), .7, { scaleY: 0, ease: "Power4.easeIn", delay: 1}, .3)
            .to($('.txt03'), 0, {opacity: 1})
            .staggerTo('.slide_txt_scroll_03', 0, {scaleY: 1}, 0)
            .staggerTo($('.slide_txt_scroll_03'), .7, {top: "101%", ease: "Power4.easeOut", delay : .1}, .3)

var scene3 = new ScrollMagic.Scene({
  triggerElement: ".slide_txt_scroll_03",
  triggerHook : "onEnter",
  reverse: false,
})
.setTween(tween3)
.addTo(controller1);

// var scene2 = new ScrollMagic.Scene({
//   triggerElement: "#box2",
// })
// .setTween(tween2)
// .addTo(controller1);

// var scrollFunc03 = function() {
//   for(var i = 0; i < verticalFadeBg.length; i++) {
//     var triggerMargin = 150;
//     var elm = verticalFadeBg[i];
//     var showPos = 0;
//     if(elm.dataset.sa_trigger) {
//       showPos = document.querySelector(elm.dataset.sa_trigger).getBoundingClientRect().top + triggerMargin;
//     } else {
//       showPos = elm.getBoundingClientRect().top + triggerMargin;
//     }
//     if (window.innerHeight > showPos) {
//       // elm.classList.add('show');
//       gsap.from(elm, .7,{
//         y: "-100%",
//         ease: "Power4.easeIn",
//         delay: 1,
//       });
//       gsap.fromTo( elm, 2, {
//         scaleY: 1,
//       },
//       {
//         y: "101%",
//         delay : 1.6, // 実行までの待ち時間
//         // force3D:0,
//         // duration: .7,
//         ease: "Power4.easeOut",
//         onComplete : function(){
//           gsap.from(elm, .7,{
//             this.pause(0);
//           });
//         },
//       });
//     } else {
//       // elm.classList.remove('show');
//       gsap.from( elm, 2, {
//         paused: true
//       });
//     }
//     if (showPos <= -300) {
//       // elm.classList.remove('show');

//     }
//   }
// }
// window.addEventListener('load', scrollFunc03);
// window.addEventListener('scroll', scrollFunc03);


// ページ遷移
$(window).on('load', function(){
  $('body').removeClass('page_load');
});

function page_load() {
  $('a:not([href^="#"]):not([target])').on('click', function(e){
    e.preventDefault();
    url = $(this).attr('href');
    if (url !== '') {
      $('body').addClass('page_load');
      setTimeout(function(){
        window.location = url;
        $('body').addClass('leave');
      }, 300);
    }
    return false;
  });
  // window.onpageshow = function(event) {
  //   if (event.persisted) {
  //     console.log('ボタンがクリックされました');
  //   }
  // };
  window.addEventListener("pageshow", function(event){
    if (event.persisted) {
      window.location.reload();
      event.preventDefault();
      url = window.location.href;
      if (url !== '') {
        // $('body').addClass('page_load');
        setTimeout(function(){
          window.location = url;
          $('body').addClass('leave');
        }, 300);
      }
      $('body').removeClass('page_load');
    }
  });
}

function click_radio() {
  $("#all").on("click",function(){
    $(".product_bamboo").removeClass("active");
    $(".product_tableware").removeClass("active");
    $('.product_all').addClass("active");
  });
  $("#bamboo").on("click",function(){
    $(".product_all").removeClass("active");
    $(".product_tableware").removeClass("active");
    $('.product_bamboo').addClass("active");
  });
  $("#tableware").on("click",function(){
    $(".product_all").removeClass("active");
    $(".product_bamboo").removeClass("active");
    $('.product_tableware').addClass("active");
  });
}

function gmenuBtn() {
  gsap.config({
    autoSleep: 60,
    force3D: false,
    nullTargetWarn: false,
  });
  document.getElementById( "burger" ).addEventListener( "click", function(){
    gsap.to( '.open_bg, .gmenu_wrap', {
      x: "0%",
      duration: 1.5,
      ease: "expo.inOut",
    });
  });
  document.getElementById( "cross" ).addEventListener( "click", function(){
    gsap.to( '.open_bg, .gmenu_wrap', {
      x: "100%",
      duration: 1.5,
      ease: "expo.inOut",
    });
  });
  $('#burger').on('click', function() {
    if ($('.open').length == 0) {
      $('header').addClass('open');
      $('.topline').queue(function(){
        $(this).addClass('del').dequeue();
      });
      $('.mdline').delay(200).queue(function(){
        $(this).addClass('del').dequeue();
      });
      $('.underline').delay(400).queue(function(){
        $(this).addClass('del').dequeue();
      });
      $('#cross .right').delay(600).queue(function(){
        $(this).removeClass('del').dequeue();
      });
      $('#cross .left').delay(800).queue(function(){
        $(this).removeClass('del').dequeue();
      });
      $('body').css({'overflow': 'hidden'});
    }
  });
  $('#cross').on('click', function() {
    $('header').removeClass('open');
    $('.topline').queue(function(){
      $(this).removeClass('del').dequeue();
    });
    $('#cross .right').delay(50).queue(function(){
      $(this).addClass('del').dequeue();
    });
    $('#cross .left').delay(100).queue(function(){
      $(this).addClass('del').dequeue();
    });
    $('.mdline').delay(300).queue(function(){
      $(this).removeClass('del').dequeue();
    });
    $('.underline').delay(500).queue(function(){
      $(this).removeClass('del').dequeue();
    });
    $('body').css({'overflow': 'visible'});
  });
  $('header a').on('click', function() {
    if ($('.open').length == 0) {
      $('header').addClass('open');
    } else {
      $('header').removeClass('open');
    }
  });
} //gmenuBtn


function footer_undermenu() {
  $('.menu_arrow').on('click', function() {
    if ($(this).hasClass('open')) {
      $(this).removeClass('open');
      $('.footer_menu .lower').slideUp(500).animate(
        { opacity: 0 });
    } else {
      $(this).addClass('open');
      $('.footer_menu .lower').slideDown(500).animate(
        { opacity: 1 },
        { queue: false, duration: 1400 }
      );
    }
  });
}


$(function() {
  to_scroll();
  page_load();
  click_radio();
  gmenuBtn();
  footer_undermenu();
});